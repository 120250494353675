header .nav-wrapper {
  background-color: #F1625B;
}

header .nav-wrapper .image {
  flex-basis: 22.5em;
  padding-right: 1.5em;
}

header .nav-wrapper .logo img {
  margin-top: 0px;
  width: 22.5em;
}

header .component .navigation .site-nav {
  padding-left: 1.6em;
}
